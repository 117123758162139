<template>
    <div class="approval-detail" v-if="pageData">
        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <router-link to><el-button icon="el-icon-arrow-left" round @click="$router.back(-1)">{{ $t('data.public.back') }}</el-button></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20" class="mb-20">
            <el-col :span="16">
                <el-card shadow="always">
                    <div class="approval-info">
                        <div class="mb-20 text-flex">
                            <div class="name-left">
                                {{ $t('data.applicationDetail.topic') }}: <br>
                                {{ $t('data.applicationDetail.group') }}: <br>
                                {{ $t('data.applicationDetail.eCertNo') }}: <br>
                                {{ $t('data.applicationDetail.timeRequested') }}:<br>
                                {{ $t('data.applicationDetail.action') }}: <br>
                                {{ $t('data.applicationDetail.approvalTime') }}: <br>
                                {{ $t('data.applicationDetail.applicantId') }}:<br><br>
                            </div>
                            <div class="val-right">
                                {{pageData.topic}}<br>
                                {{pageData.group.name}}<br>
                                {{pageData.number}}<br>
                                {{pageData.created_at}}<br>
                                <span v-if="pageData.status === 1">{{ $t('data.approvalStatus.pending') }}</span>
                                <span v-else-if="pageData.status === 2">{{ $t('data.approvalStatus.approved') }}</span>
                                <span v-else-if="pageData.status === 3">{{ $t('data.approvalStatus.disapproved') }}</span>
                                <br>
                                {{pageData.issue_date}}<br>
                                {{pageData.member.name}}<br><br>
                            </div>
                        </div>
                        <img :src="pageData.picture" alt="">
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">

                <el-card shadow="always">
                    <div class="approval-scan">
                        <p>{{ $t('data.applicationDetail.scanQRCode') }}</p>
                        <p>{{ $t('data.applicationDetail.eCertTitle') }}: {{pageData.topic}}</p>
                        <img :src="pageData.qrcode" alt="">
                        <p>#{{pageData.member.name_on_certificate}}</p>
                        <div class="approval-scan-btn">
                            <el-button type="primary" size="small" class="mb-10" @click="copyLink(pageData.app_link)">{{ $t('data.public.emailLink') }}</el-button>
                            <router-link :to="'/ecert/qrcode-print?cert_id='+certId" style="margin-left: 10px;"  class="mb-10">
                                <el-button type="primary" size="small" class="mb-10">{{ $t('data.public.printQRcode') }}</el-button>
                            </router-link>
                            <!-- <el-button type="primary" size="small" class="mb-10" style="margin-left: 10px;" @click="openPdf(pageData.pdf)">{{ $t('data.public.printeCert') }}</el-button> -->
                            <router-link :to="'/ecert/print?cert_id='+certId" style="margin-left: 10px;"  class="mb-10">
                                <el-button type="primary" size="small">{{ $t('data.public.printeCert') }}</el-button>
                            </router-link>
                        </div>
                    </div>
                </el-card>
                
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { certsDetails } from '../../api/index'
export default {
    name: "Applicant Detail",
    data() {
        return {
            pageData: null,
            certId: this.$route.params.id
        }
    },
    mounted(){
        this.initPageData();
    },
    methods:{
        initPageData(){
            let apiData = {
              id: this.$route.params.id
            }
            certsDetails({apiData}).then( res => {
                this.pageData = res.data;
            })
        },
        copyLink(shareLink){
            var input = document.createElement("input");
            input.value = shareLink;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            document.body.removeChild(input);
            ElMessage.success({
                message: 'Copy Success',
                type: 'success'
            });
        },
    }
}
</script>

<style scoped>
.approval-info{
    text-align: center;
}
.approval-info p{
    text-align: left;
    line-height: 32px;
}
.approval-ststus{
    display: block;
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
}
.approval-info img{
    width: 400px;
    height: auto;
    margin: 0 auto;
    text-align: center;
}
.approval-info > .text-flex > div{
    line-height: 28px
}
.approval-scan{
    text-align: center;
}
.approval-scan p{
    margin-bottom: 15px;
}
.approval-scan img{
    max-width: 200px;
}
.copy-link-btn{
    display: block;
    margin: 15px 0px;
}

</style>